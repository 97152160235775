
.submitButton{
  border-radius:10px;
  box-shadow: 0px 8px 12px rgba(10,0,180,0.2);
  padding:20px;
  font-family: "Comfortaa", cursive;
  padding-top: 1.2vh;
  padding-bottom: 1.2vh;
  width:30%;
  margin-top: 6vh;
  border:0px;
  color:white;
  background:#4818b3;
  position: absolute;
  right:0px;
}
.submitButton:hover{
  cursor:pointer;
  opacity:0.7;
}

.submitButtonText{
  vertical-align: middle;
  display: inline-block;
  margin-right: 1vw;
}

.submitButtonIcon{
  height:2.2vh;
  vertical-align: middle;
  display: inline-block;
}
