@font-face {
  font-family: GothamPro;
  src: url("GothamPro-Medium.ttf");
}

@font-face {
  font-family: GothamProBold;
  src: url("GothamPro-Bold.ttf");
}

//Time Picker



.rc-time-picker {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width:250px;
  margin-left: -3vw;
}
.rc-time-picker * {
  box-sizing: border-box;
}
.rc-time-picker-clear {
  position: absolute;
  right: 6px;
  cursor: pointer;
  overflow: hidden;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 20px;
  top: 3px;
  margin: 0;
}
.rc-time-picker-clear-icon:after {
  content: "x";
  font-size: 12px;
  font-style: normal;
  color: #aaa;
  display: inline-block;
  line-height: 1;
  height: 28px;
  width: 28px;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.rc-time-picker-clear-icon:hover:after {
  color: #666;
}
.rc-time-picker-input {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 4px 7px;
  height: 38px;
  cursor: text;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5;
  color: #666;
  background-color: #fff;
  background-image: none;
  border: 1px solid #BCBCBC;
  border-radius: 8px;
  -webkit-transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.rc-time-picker-input[disabled] {
  color: #ccc;
  background: #f7f7f7;
  cursor: not-allowed;
}
.rc-time-picker-panel {
  z-index: 1070;
  width: 170px;
  position: absolute;
  box-sizing: border-box;
}
.rc-time-picker-panel * {
  box-sizing: border-box;
}
.rc-time-picker-panel-inner {
  display: inline-block;
  position: relative;
  outline: none;
  list-style: none;
  font-size: 12px;
  text-align: left;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 5px #ccc;
  background-clip: padding-box;
  border: 1px solid #ccc;
  line-height: 1.5;
}
.rc-time-picker-panel-narrow {
  max-width: 113px;
}
.rc-time-picker-panel-input {
  margin: 0;
  padding: 0;
  width: 100%;
  cursor: auto;
  line-height: 1.5;
  outline: 0;
  border: 1px solid transparent;
}
.rc-time-picker-panel-input-wrap {
  box-sizing: border-box;
  position: relative;
  padding: 6px;
  border-bottom: 1px solid #e9e9e9;
}
.rc-time-picker-panel-input-invalid {
  border-color: red;
}
.rc-time-picker-panel-select {
  float: left;
  font-size: 12px;
  border: 1px solid #e9e9e9;
  border-width: 0 1px;
  margin-left: -1px;
  box-sizing: border-box;
  width: 56px;
  max-height: 144px;
  overflow-y: auto;
  position: relative;
}
.rc-time-picker-panel-select-active {
  overflow-y: auto;
}
.rc-time-picker-panel-select:first-child {
  border-left: 0;
  margin-left: 0;
}
.rc-time-picker-panel-select:last-child {
  border-right: 0;
}
.rc-time-picker-panel-select ul {
  list-style: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
}
.rc-time-picker-panel-select li {
  list-style: none;
  margin: 0;
  padding: 0 0 0 16px;
  width: 100%;
  height: 24px;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.rc-time-picker-panel-select li:hover {
  background: #edfaff;
}
li.rc-time-picker-panel-select-option-selected {
  background: #f7f7f7;
  font-weight: bold;
}
li.rc-time-picker-panel-select-option-disabled {
  color: #ccc;
}
li.rc-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}










.bp3-tabs{
  display: inline-block;
  vertical-align: middle;
  font-family: "Comfortaa", cursive;
  width:100%;
  padding:20px;
  height:90%;
}

.bp3-tab{
  color:#6F87FF;
  padding-left: 4px;
  overflow-y:auto;
  height:100%;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #989898;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #989898;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #989898;
}

.global-loading-page{
  width:100%;
  height:100%;
  text-align: center;
  background: #f5f6ff;
  position: fixed;
  top:0px;
  left:0px;
}

.global-loader{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}

.global-container{
  width:100%;
  height:100%;
  text-align: center;
  background: #f5f6ff;
  position: fixed;
  top:0px;
  left:0px;
}

.global-card-view{
  background: #fff;
  border-radius:24px;
  box-shadow: 0px 5px 12px rgba(10,0,180,0.1);
  padding:30px;
}

.global-login-card-view{
  background: #fff;
  border-radius:24px;
  box-shadow: 0px 5px 12px rgba(10,0,180,0.1);
  padding:10px;
  min-width:550px;
}


.global-mobile-logo{
  margin-bottom: 8vh;
  width:60vw;
}

.global-mobile-popup{
  font-size: 1.4rem;
  padding:2vh;
  text-align: center;
  color:#5d00d6;
  font-family: "Comfortaa", cursive;
  width:80vw;
}

.global-header{
  width:100%;
  background:#4818b3;
  padding-top:1.3vh;
  padding-bottom:1.3vh;
  display: flex;
}

.global-header-profile{
  display:inline-block;
  text-align: left;
  margin-left: 2vw;
  padding:0.4vh;
  padding-left: 1.2vw;
  padding-right:1.2vw;
  border-radius: 100px;
  background:white;
}

.global-header-profile-image{
  width:3vh;
  border-radius:10px;
  height:3vh;
  display: inline-block;
  vertical-align: middle;
  margin-top: 1px;
  background:white;
}

.global-header-profile-text{
  color:#191919;
  font-size: 0.9rem;
  font-family: GothamPro;
  font-weight: normal;
  display: inline-block;
  vertical-align: middle;
  margin-left: 1vw;
  margin-top: 3px;
}

.global-header-buttons{
  flex:1;
  display:inline-block;
  text-align: right;
  vertical-align: middle;
  margin-right:30px;
}

@keyframes headerButtonTilt {
  from {transform:rotate(0deg);}
  to {transform:rotate(20deg);}
}


.global-header-button{
  display: inline-block;
  vertical-align: middle;
  margin-top: 0px;
  width:20px;
  margin-right: 20px;
  padding:5px;
  background:white;
  border-radius: 40px;
  opacity:0.9;
}

.global-header-button:hover{
  cursor:pointer;
  opacity:0.7;
  animation-name: headerButtonTilt;
  animation-duration: 0.4s;
  transform:rotate(20deg);
}

.global-dashboard-row{
  width:100%;
  margin-top: 20px;
  min-height:10vh;
  display: flex;
  flex-direction: row;
}

.global-dashboard-row-large{
  width:100%;
  margin-top: 2vh;
  margin-bottom:10px;
  min-height:70vh;
  display: flex;
  flex-direction: row;
}

.global-dashboard-row-small{
  width:100%;
  margin-top: 20px;
  margin-bottom:10px;
  min-height:16vh;
  display: flex;
  flex-direction: row;
}

.global-dashboard-row-card{
  background:white;
  box-shadow: 0px 2px 7px #0000B422;
  display:flex;
  flex-direction: row;
  flex:1;
  margin:10px;
  border-radius:12px;
  height:100%;
  overflow-y: hidden;
  position: relative;
  background-repeat: no-repeat;
  background-image: url('../images/dashboard-widget-bg.png');
  background-position: top;
  background-size: cover;
  align-items: center;
}

.global-dashboard-row-card-white{
  background:white;
  box-shadow: 0px 2px 7px #0000B422;
  display:flex;
  flex-direction: column;
  flex:1;
  margin:10px;
  border-radius:12px;
  height:100%;
  overflow-y: hidden;
  position: relative;
}

.card-flex-column{
  flex-direction: column;
}

.global-dashboard-row-card-wide{
  background:white;
  box-shadow: 0px 2px 7px #0000B422;
  display:flex;
  flex:2;
  margin:10px;
  border-radius:12px;
  height:100%;
  overflow-y: hidden;
  position: relative;
  flex-direction: column;
}

.global-card-border-pink{
  position: absolute;
  top:10%;
  left:0px;
  height:80%;
  width:4px;
  background:#E90062;
  border-top-right-radius:100px;
  border-bottom-right-radius:100px;
  opacity:1.0;
}

.global-card-border-purple{
  position: absolute;
  top:10%;
  left:0px;
  height:80%;
  width:4px;
  background:linear-gradient(0deg,#6F00E9,#6F87FF);
  border-top-right-radius:100px;
  border-bottom-right-radius:100px;
  opacity:1.0;
}

.global-card-border-light-purple{
  position: absolute;
  top:10%;
  left:0px;
  height:80%;
  width:4px;
  background:linear-gradient(0deg,#6F00E9,#6F87FF);
  border-top-right-radius:100px;
  border-bottom-right-radius:100px;
  opacity:1.0;
}

.global-card-border-blue{
  position: absolute;
  top:10%;
  left:0px;
  height:80%;
  width:4px;
  background:linear-gradient(0deg,#3300D4,#13B1FF);
  border-top-right-radius:100px;
  border-bottom-right-radius:100px;
  opacity:1.0;
}

.global-card-border-light-blue{
  position: absolute;
  top:10%;
  left:0px;
  height:80%;
  width:4px;
  background:linear-gradient(0deg,#3300D4,#13B1FF);
  border-top-right-radius:100px;
  border-bottom-right-radius:100px;
  opacity:1.0;
}

.global-card-border-blue-right{
  position: absolute;
  top:10%;
  right:0px;
  height:80%;
  width:4px;
  background:linear-gradient(0deg,#3300D4,#13B1FF);
  border-top-left-radius:100px;
  border-bottom-left-radius:100px;
  opacity:1.0;
}

.logistible-tab{
  color: #8080da;
}

.bp3-tab-indicator{
  background-color: #8080da;
}

.global-dashboard-row-card-header{
  background:#5f59ff;
  padding:5px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
}

.global-card-header-title{
  color:white;
  font-family: "Comfortaa", cursive;
  font-size: 0.8rem;
  text-align:left;
}

.global-card-header-menu-container{
  flex:1;
  text-align: right;
  justify-content: flex-end;
}

.global-card-menu-icon{
  height:12px;
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
}

.global-card-menu-icon:hover{
  cursor:pointer;
}

.global-header-search-bar{
  display: inline-block;
  vertical-align: middle;
  margin-left: 14vw;
  border-radius: 6px;
  padding:10px;
  padding-top: 7px;
  padding-bottom:7px;
  background:#7799ff22;
  width:30vw;
  display:flex;
}

.global-header-search-icon{
  height:16px;
  margin-top: 1px;
  margin-right: 10px;
  opacity: 0.9;
}

.global-header-search-input{
  flex:1;
  font-size: 0.9rem;
  color:#333333;
  outline: none;
  border:0px;
  font-family: "Comfortaa", cursive;
  background:#00000000;
}

.global-header-search-input:focus{
  outline:none;
}

.dropdownInput{
  background:white;
  border-radius:12px;
  box-shadow: 0px 2px 12px rgba(10,0,180,0.1);
  padding:30px;
  font-family: "Comfortaa", cursive;
  padding-top: 1vh;
  padding-bottom: 1vh;
  width:14.5vw;
  margin-top: 5px;
  border:0px;
  color:#989898;
}
.dropdownInput:focus{
  outline:none;
}


.react-datepicker {
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  font-size: .7rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  display: inline-block;
  position: relative;
}
