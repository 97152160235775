@font-face {
  font-family: GothamPro;
  src: url("../../styles/GothamPro-Medium.ttf");
}

.hashtagInput{
  border-radius: 100px;
  border:0px;
  border:1px solid #BCBCBC;
  padding:5px;
  margin-left: 10px;
  width:40%;
}
.hashtagInput:focus{
  outline: none;
}

.hashtag{
  padding:5px;
  display: flex;
  border-bottom:1px solid #DBDBDB;
  font-family: "Montserrat";
}

.continentDisplay{
  padding:10px;
  font-family: "Montserrat";
  font-weight:bold;
}

.continentDisplayText{
  padding:4px;
  padding-left: 16px;
  padding-right: 24px;
  font-family: "Montserrat";
  font-weight:bold;
  color:white;
  background:#ec008e;
  border-radius: 100px;
  display: inline-block;
}

.countryDisplay{
  padding:5px;
  padding-left:15px;
  font-family: "Montserrat";
  font-size: 0.8rem;
}

.hashtagButtonContainer{
  flex:1;
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  display: inline-block;
  vertical-align: middle;
}

.hashtagButton{
  border-radius: 100px;
  color:white;
  padding:0px;
  text-align: center;
  padding-left:8px;
  padding-right:8px;
  background:#f00038;
  display: inline-block;
  vertical-align: middle;
  font-size: 1.3rem;
  opacity: 0.9;
  margin-right: 20px;
}
.hashtagButton:hover{
  cursor: pointer;
  opacity:0.7;
}

.hashtagsSubheader{
  margin-top: 10px;
  padding-bottom:14px;
  border-bottom:1px solid #DBDBDB;
}

.socialOption{
  margin:10px;
  border-radius: 8px;
  border: 1px solid #989898;
  padding:10px;
  display: flex;
  flex-direction: row;
}

.socialOptionIcon{
  height:2.4vw;
  border-radius:200px;
  margin-right: 2vw;
}

.socialOptionName{
  font-family: "Montserrat";
  color:#989898;
  font-size: 1.1rem;
  flex:1;
  justify-content: center;
  align-items: center;
}

.socialOptionButtonContainer{
  flex:1;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
}

.socialOptionButtonLogin{
  vertical-align: middle;
  padding:5px;
  padding-left: 1vw;
  padding-right: 1vw;
  background:#00DF83;
  border-radius: 4px;
  color:white;
  font-family: "Montserrat";
  font-size: 1rem;
  display: inline-block;
  border:0px;
}
.socialOptionButtonLogin:hover{
  cursor: pointer;
  opacity:0.7;
}

.socialOptionButtonLogout{
  vertical-align: middle;
  padding:5px;
  padding-left: 1vw;
  padding-right: 1vw;
  background:#F4004B;
  border-radius: 4px;
  color:white;
  font-family: "Montserrat";
  display: inline-block;
}
.socialOptionButtonLogout:hover{
  cursor: pointer;
  opacity:0.7;
}

.homeContentContainerMain{
  position: absolute;
  top: 0vh;
  width:calc(100%);
  height:calc(100%);
  display: flex;
  flex-direction: row;
}

.homeDashboardContentMain{
  height:100%;
  flex:5;
  display:flex;
  flex-direction: column;
  overflow-y: auto;
}

.homeContentRow{
  flex:1;
  height:16vh;
  display: flex;
  margin-bottom: 4vh;
  background:blue;
}

.homeContentCardMiniMain{
  height:100%;
  flex:1;
  background: #fff;
  border-radius:0px;
  box-shadow: 0px 5px 12px rgba(10,0,180,0.1);
  padding:16px;
  margin-left: 1vw;
  margin-right: 1vw;
  position: relative;
}

#homeContentCardMiniHeader{
  color:white;
  text-shadow: 2px 2px 3px 3px #3300BB;
  padding-bottom: 3vh;
}

.homeContentCardMiniHeader{
  color:#8980ad;
  font-family: "Montserrat";
  font-size: 1rem;
  padding:0.5vw;
  padding-top:0.5vh;
  text-align: left;
}

.homeRecentRevenue{
  color:white;
  font-weight: 600;
  font-family: "Montserrat";
  font-size: 2.2rem;
  padding-left:0.5vw;
  padding-right: 0.5vw;
  text-align: center;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  padding-top: 1vh;
}

.bookingsCard{
  margin-top: 6vh;
  display: flex;
  flex:4;
  flex-direction:column;
  background: #fff;
  border-radius:24px;
  box-shadow: 0px 5px 12px rgba(10,0,180,0.1);
  padding:16px;
  margin-left: 1vw;
  margin-right: 1vw;
  overflow-y: auto;
}

#homeRecentBookingsTable{
  padding-top: 3vh;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
}

.homeRecentBookingsTableHeader{
  width:100%;
  display: flex;
  flex-direction: row;
  padding-top:1vh;
  padding-bottom:1vh;
  background:#f03a5e;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.homeRecentBookingsRowEven{
  width:100%;
  display: flex;
  flex-direction: row;
  padding-top: 1vh;
  padding-bottom: 0.8vh;
}
.homeRecentBookingsRowOdd{
  width:100%;
  display: flex;
  flex-direction: row;
  padding-top: 1vh;
  padding-bottom: 0.8vh;
  background:#f5f6ff;
}

.homeResultHeaderItem{
  color:white;
  font-family: "Montserrat";
  font-size: 0.9rem;
  flex:1;
}

.homeRecentBookingsRowOddItem{
  color:#8980ad;
  font-family: "Montserrat";
  font-size: 0.9rem;
  flex:1;
}

.homeRecentBookingsRowEvenItem{
  color:#8980ad;
  font-family: "Montserrat";
  font-size: 0.9rem;
  flex:1;
}

#home-bookings-header{
  flex-direction: row;
}

.homeRecentBookingsTitleContainer{
  text-align: left;
  display: flex;
}

.homeRecentBookingsSearchContainer{
  text-align: right;
  flex:1;
  padding:10px;
  display: flex;
}

.homeCardHeaderPlain{
  padding:8px;
  padding-left: 12px;
  text-align: left;
}

.homeCardHeaderIcon{
  display:inline-block;
  vertical-align: middle;
  width:26px;
}

.homeCardHeaderText{
  display:inline-block;
  vertical-align: middle;
  color:#343434;
  font-family: "Montserrat";
  font-size: 1rem;
  margin-left: 10px;
}

.dashboardCardLarge{
  background:white;
  border-radius:6px;
  flex-direction: column;
  flex:1;
  margin:14px;
  display: flex;
}

.dashboardCardHeader{
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-top: 6px;
  padding-bottom:10px;
  background:#191919;
  display: flex;
  flex-direction: row;
}

.dashboardCardRow{
  margin-top: 5px;
  text-align: left;
  flex:1;
  display:flex;
  align-items: center;
}

.dashboardCardContent{
  flex-direction: column;
  flex:1;
  text-align: left;
  overflow-y: auto;
}

.dashboardCardFooter{
  padding-top:10px;
  padding-bottom: 10px;
  border-top:1px solid #BCBCBC;
}

.dashboardCardFooterText{
  font-family: "Montserrat";
  color:#787878;
  font-size: 0.9rem;
}

.dashboardCardPageInput{
  border:1px solid #787878;
  margin-left: 10px;
  margin-right: 10px;
  width:40px;
  text-align: center;
  padding:5px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 100px;
  color:#787878
}

.dashboardCardPageButton{
  width:20px;
  height:20px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  margin-right: 10px;
}

.dashboardCardPageButton:hover{
  cursor:pointer;
  opacity:0.7;
}


.discountsRecentBookingsTable{
  margin-top: 0vh;
  padding:0px;
  border:0px solid #e5e6ef;
  padding-top: 0px;
  border-radius:16px;
  width:100%;
}


.discountsRecentBookingsTableHeader{
  width:100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding-top:0.8vh;
  font-size:0.9rem;
  padding-bottom:0.8vh;
  background:#BDBDFF;
  margin:0px;
}

.discountsRowEven{
  width:100%;
  display: flex;
  flex-direction: row;
  padding-top: 1.2vh;
  padding-bottom: 1vh;
  margin:0px;
}
.discountsRowOdd{
  width:100%;
  display: flex;
  flex-direction: row;
  padding-top: 1.2vh;
  padding-bottom: 1vh;
  background:#f5f6ff;
  margin:0px;
}

.discountsRowEven:hover{
  cursor: pointer;
}
.discountsRowOdd:hover{
  cursor: pointer;
}

.discountsResultHeaderItem{
  color:white;
  font-family: "Montserrat";
  font-size: 0.9rem;
  flex:1;
  margin:0px;
}

.discountsRecentBookingsRowOddItem{
  color:#8980ad;
  font-family: "Montserrat";
  font-size: 0.9rem;
  flex:1;
  margin:0px;
}

.discountsRecentBookingsRowEvenItem{
  color:#8980ad;
  font-family: "Montserrat";
  font-size: 0.9rem;
  flex:1;
  padding:0px;
  margin:0px;
  display: inline-block;
  vertical-align: middle;
}

.discountsNewProductWindowContainer{
  position: fixed;
  top:0px;
  left:0px;
  z-index:200;
  width:100%;
  height:100%;
  background:#AEAEC1BB;
  backdrop-filter: blur(3px);
}

@keyframes productsPopup {
  from {opacity:0;}
  to {opacity:1;}
}

.discountsNewProductWindow{
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:45%;
  height:45%;
  background:white;
  border-radius:14px;
  border:1px solid #3814ba;
  box-shadow: 0px 8px 12px #7B78CC;
  animation-name: productsPopup;
  animation-duration: 0.9s;
  font-family: "Montserrat";
  font-size: 1rem;
  color:#484848;
  display: inline-block;
  text-align: left;
  overflow-y: auto;
}

.discountsNewProductHeader{
  padding:2vh;
  color:#989898;
}

.discountsFormNote{
  margin-top: 3vh;
  padding-right: 10vw;
  color:#989898;
}

.discountsForm{
  padding:2vw;
  padding-top: 1vh;
  color:#484848;
}

.discountsRecentBookingsTitleIcon{
  display: inline-block;
  vertical-align: middle;
  width:32px;
  margin-right: 20px;
  margin-left: 10px;
}
.discountsRecentBookingsTitleText{
  display: inline-block;
  vertical-align: middle;
  margin-top: 8px;
}

.discountsXButton{
  position: absolute;
  top:20px;
  right:20px;
  width:16px;
}

@keyframes productsSpinner {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

.discountsXButton:hover{
  cursor:pointer;
  animation-name: productsSpinner;
  animation-duration: 0.8s;
}


.superAdminPlaceholderContainer{
  display: inline-block;
  vertical-align: middle;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 20px;
  text-align: left;
}

.superAdminPlaceholderTitle{
  color:#ABABAB;
  font-size: 0.9rem;
  font-family: 'Nunito', sans-serif;
}

.superAdminPlaceholder{
  background:white;
  border-radius:12px;
  box-shadow: 0px 2px 12px rgba(10,0,180,0.1);
  padding:30px;
  font-family: "Montserrat";
  padding-top: 1.2vh;
  padding-bottom: 1.2vh;
  font-size: 0.9rem;
  width:13.5vw;
  margin-top: 5px;
  border:0px;
  color:#787878;
  background:rgba(10,0,210,0.1);
}

.formButtonContainer{
  text-align: right;
  margin-right: 3.5vw;
  margin-top: 3vh;
}

.noOrdersContainer{
  margin-top: 16vh;
  color:#ABABAB;
  font-size: 1.1rem;
  font-family: "Nunito", sans-serif;
}

.noOrdersGraphic{
  width:14vw;
  margin-bottom: 6vh;
}

.discountsFormColoursTitle{
  color:#ABABAB;
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 0.9rem;
  font-family: 'Nunito', sans-serif;
  display: inline-block;
  vertical-align: middle;
}

.discountsFormColour{
  background: #627EFB22;
  padding: 14px;
  border-radius: 10px;
  margin-top: 1.6vh;
  margin-right: 50px;
  color:#989898;
  font-size: 0.9rem;
}

.colorPicker{
  display: inline-block;
  vertical-align: middle;
  margin-top: 20px;
  font-size: 0.8rem;
}

.colorPickerSquare{
  width:30px;
  height:30px;
  margin-top: 10px;
}

.headerIcon{
  display: inline-block;
  vertical-align: middle;
  width:17px;
  margin-left: 20px;
}

.headerText{
  display: inline-block;
  vertical-align: middle;
  font-family: "Montserrat";
  margin-left: 20px;
  color:white;
  font-size: 0.9rem;
}

.noticeText{
  font-family: "Montserrat";
  margin-top: 4vh;
  color:#989898;
  text-align: left;
  padding-left: 24px;
}

.inputInfo{
  font-family: "Montserrat";
  margin-top: 4vh;
  color:#989898;
  text-align: left;
  padding-left: 24px;
  font-size: 0.9rem;
}

.myCompanyLogo{
  width:120px;
  margin-top: 30px;
  border-radius: 14px;
  margin-left: 24px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}

.headerRowRight{
  display: inline-block;
  vertical-align: middle;
  text-align: right;
  align-items: flex-end;
  flex:1;
  flex-direction: row;
  padding-right: 30px;
}

.colourPickerContainer{
  margin-left: 20px;
}

.productsRecentBookingsTable{
  margin-top: 0vh;
  padding:0px;
  border:0px solid #e5e6ef;
  padding-top: 0px;
  border-radius:16px;
  width:100%;
}


.productsRecentBookingsTableHeader{
  width:100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding-top:0.8vh;
  font-size:0.9rem;
  padding-bottom:0.8vh;
  background:#BDBDFF;
  margin:0px;
}

.productsRowEven{
  width:100%;
  display: flex;
  flex-direction: row;
  padding-top: 1.2vh;
  padding-bottom: 1vh;
  margin:0px;
}
.productsRowOdd{
  width:100%;
  display: flex;
  flex-direction: row;
  padding-top: 1.2vh;
  padding-bottom: 1vh;
  background:#f5f6ff;
  margin:0px;
}

.productsRowEven:hover{
  cursor: pointer;
}
.productsRowOdd:hover{
  cursor: pointer;
}

.productsResultHeaderItem{
  color:white;
  font-family: "Montserrat";
  font-size: 0.9rem;
  flex:1;
  margin:0px;
  text-align: center;
}

.productsRecentBookingsRowOddItem{
  color:#8980ad;
  font-family: "Montserrat";
  font-size: 0.9rem;
  flex:1;
  margin:0px;
  text-align: center;
}

.productsRecentBookingsRowEvenItem{
  color:#8980ad;
  font-family: "Montserrat";
  font-size: 0.9rem;
  flex:1;
  padding:0px;
  margin:0px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
